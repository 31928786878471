
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import useCohorts from '@/composables/useCohorts';
import { Cohort } from '@/types/Cohort';

export default defineComponent({
  name: 'Detail',
  components: {},
  setup() {
    const route = useRoute();
    const {
      cohorts,
      getCohortByRouteName,
      COHORT_TYPES,
      COHORT_TYPES_2_NO_EMPTY,
      STUDY_TYPES,
      INDICATION_FIELDS,
      PRIMARY_BLOOD_TUBE_TYPES,
      URINE_COMPONENT_TYPES,
      SMEAR_TYPES,
      PARTICIPANT_TYPES,
      GENDER_TYPES,
      toRecruitmentPeriodString,
    } = useCohorts();

    let cohort: Ref<Cohort | undefined> = ref();
    const getCohort = () => {
      if (typeof route.params.name === 'string') {
        cohort.value = getCohortByRouteName(route.params.name);
      }
    };

    // load cohort on page load
    onMounted(() => getCohort());

    // reload cohort if route changes
    watch(
      () => route.params.name,
      () => {
        getCohort();
      }
    );

    // load cohort when cohorts are loaded by store
    watch(
      () => cohorts.value,
      () => {
        getCohort();
      }
    );

    const indicationFields = computed(() => {
      return cohort.value?.characteristics.indication
        ?.flatMap(
          (indication) =>
            INDICATION_FIELDS.find((x) => x.value === indication)?.name
        )
        .concat(cohort.value.characteristics.indicationOther);
    });

    const cohortType = computed(() => {
      if (!cohort.value) return null;
      let str =
        COHORT_TYPES.find(
          (e) => e.value === cohort.value?.characteristics.cohortType
        )?.name + ' ';
      if (cohort.value.characteristics.studyType) {
        str += STUDY_TYPES.find(
          (e) => e.value === cohort.value?.characteristics.studyType
        )?.name;
      } else if (cohort.value.characteristics.studyTypeOther) {
        str += cohort.value.characteristics.studyTypeOther;
      }

      str += ' - ';
      str += COHORT_TYPES_2_NO_EMPTY.find(
        (e) => e.value === cohort.value?.characteristics.cohortType2
      )?.name;

      return str;
    });

    const recruitmentPeriod = computed(() => {
      return toRecruitmentPeriodString(
        cohort.value?.recruitment.start,
        cohort.value?.recruitment.end
      );
    });

    return {
      cohort,
      indicationFields,
      cohortType,
      recruitmentPeriod,
      PRIMARY_BLOOD_TUBE_TYPES,
      URINE_COMPONENT_TYPES,
      SMEAR_TYPES,
      PARTICIPANT_TYPES,
      GENDER_TYPES,
    };
  },
});
